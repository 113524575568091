import Cookies from 'js-cookie';

export default {
  methods: {
    async attributeUser() {
      const visitorId = Cookies.get('visitor_id') ?? localStorage.getItem('visitor_id');
      const fbp = localStorage.getItem('fpb') ?? Cookies.get('_fbp');
      const fbc = localStorage.getItem('fbc') ?? Cookies.get('_fbc');
      const amplitudeId = localStorage.getItem('amplitudeId') ?? amplitude.getDeviceId();
      const adsData = localStorage.getItem('ads_data') ? JSON.parse(localStorage.getItem('ads_data')) : this.getAdsData();
      const attibutionData = this.collectFilledFields({
        visitorId,
        fbp,
        fbc,
        amplitudeId,
        adsData,
      });
      this.storeUserAttributionToLocalStorage(attibutionData);
      await this.storeUserAttributionToServer(attibutionData);
    },
    getAdsData() {
      let adsData = {};
      let searchParams = new URLSearchParams(window.location.search);
      if (searchParams.get('utm_source')) {
        for (const [key, value] of searchParams.entries()) {
          adsData[key] = value;
        }
      }
      return adsData;
    },
    collectFilledFields(obj) {
      const filledFields = {};
      for (const key in obj) {
        if (
          obj[key] !== null &&
                    obj[key] !== undefined &&
                    obj[key] !== '' &&
                    obj[key] !== 'null' &&
                    obj[key] !== 'undefined'
        ) {
          filledFields[key] = obj[key];
        }
      }
      return filledFields;
    },
    async storeUserAttributionToServer(data) {
      const { visitorId, fbp, fbc, amplitudeId, adsData } = data;
      await this.$axios.post(
        route('attribution'),
        this.collectFilledFields({ fbp, fbc, amplitudeId, visitor_id: visitorId, ads_data: adsData }),
      );
    },
    storeUserAttributionToLocalStorage(data) {
      const { visitorId, fbp, fbc, amplitudeId, adsData } = data;
      if (visitorId) {
        localStorage.setItem('visitor_id', visitorId);
      }
      if (fbp) {
        localStorage.setItem('fbp', fbp);
      }
      if (fbc) {
        localStorage.setItem('fbc', fbc);
      }
      if (amplitudeId) {
        localStorage.setItem('amplitudeId', amplitudeId);
      }
      if (adsData) {
        localStorage.setItem('ads_data', JSON.stringify(adsData));
      }
    },
  },
};
